<template>
  <data-table-editable v-if="relationField"
                       :entity-type="formattedModuleName"
                       :dtFilters="dtFilters"
                       :data-filters="dataFilters"
                       :field-props="fieldProps"
                       :relation-field="relationField"
                       class="expand-field"
                       v-model="model"
  />
  <!-- no need to emit @input="$emit('input', $event)" -->
</template>

<script>
import DataTableEditable from "@/modules/erp_entities/components/page/table/DataTableEditable.vue"
import FormFieldMixin from "@/modules/erp_entities/mixins/FormFieldMixin"
import { getRelationField } from "@/utilities/helper"

import pluralize from 'pluralize'

export default {
  name: "EditableDataTableField",
  components: { DataTableEditable },
  mixins: [FormFieldMixin],
  props: {
    dataFilters: {
      required: false,
    },
  },
  data() {
    return {
      relationField: false,
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
    formattedModuleName(){
      return pluralize(this.fieldProps.data_module).toKebabCase()
    },
    dtFilters() {
      return [
        {
          prop: this.relationField,
          compare: 'in',
          value: [this.$route.params.id || 0 ],
        },
      ]
    },
  },
  async created() {
    // throw nice js errors if backend is not configured properly, todo refactor #19281651
    if(!this.fieldProps.data_type){
      console.error('data_type on '+ this.fieldProps.name +' is required')
      return
    } else if (this.fieldProps.data_type === 'moduleNames' && !this.fieldProps.data_module){
      console.error('data_module on '+ this.fieldProps.name +' is required')
      return
    }

    this.relationField = await getRelationField(this.$route.params.entityType, this.fieldProps.data_module)
  },
}
</script>